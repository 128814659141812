@import "../../styles/variables";

.wrapper {
  background: $soft-white;

  h2 {
    text-align: center;

    @media (min-width: 1024px) {
      text-align: left;
    }
  }
}

.logoContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  justify-content: space-between;
  align-items: center;

  a {
    text-align: center;
  }

  img {
    object-fit: contain;
  }

  @media (min-width: 700px) {
    grid-template-columns: repeat(3, auto);
    grid-gap: 4rem;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, auto);
    grid-gap: 4rem;
  }
}
