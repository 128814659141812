@import "../../styles/variables";

.wrapper {
  margin: 0 auto;
  height: 6.25rem;
  background-color: $text-color-secondary;
  position: fixed;
  left: 0;
  right: 0;
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 2;

  @media (min-width: 1100px) {
    nav {
      display: flex;
      height: 100%;
    }
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  padding-bottom: 1rem;

  @media (min-width: 1100px) {
    width: 20%;
    margin-right: auto;
  }

  @media (min-width: 1100px) and (max-width: 1400px) {
    a img {
      width: 9rem;
      height: auto;
    }
  }

  @media (min-width: 1400px) {
    padding-left: 2rem;
  }

  @media (min-width: 1500px) {
    padding-left: 3rem;
  }
}

.menuList {
  overflow: hidden;
  background-color: $text-color-secondary;
  padding: 0 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: height 0.3s linear;
  margin: 0;

  li + li {
    margin-top: 1.1rem;
  }

  li:last-child {
    margin-bottom: 1.1rem;
  }

  @media (min-width: 1100px) {
    height: 100%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    padding: 0;
    margin-left: 2.5rem;

    li {
      text-align: center;

      a {
        font-size: 0.875rem;
      }
    }

    li + li {
      margin-top: 0;
    }

    li:last-child {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }

  @media (min-width: 1400px) {
    gap: 2.5rem;
    margin-left: 4.5rem;
  }
  @media (min-width: 1600px) {
    margin-left: 8.5rem;
  }
}

.open {
  height: 16rem;

  @media (min-width: 1100px) {
    height: initial;
  }
}

.close {
  height: 0;

  @media (min-width: 1100px) {
    height: initial;
  }
}

.menuItem {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 2px;
    bottom: -4px;
    left: 2px;
    right: 0;
    background-color: $brand-color;
    transform-origin: left;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  &:hover::before,
  &:focus::before {
    transform: scaleX(0.6);
  }
}

.mobileBtn {
  outline: none;
  background-color: $text-color-secondary;
  border: none;

  @media (min-width: 1100px) {
    display: none;
  }
}

.cta {
  display: none;

  @media (min-width: 1230px) {
    width: 14.625rem;
    max-width: 430px;
    margin-left: auto;
    background-color: $brand-color;
    color: $text-color-secondary;
    font-weight: bold;
    font-size: 1.125rem;
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: background-color 0.5s;

    &:hover,
    &:focus {
      background-color: $highlight-color;
    }

    svg {
      width: 3.75rem;
      transition: transform 0.5s;
    }

    &:hover svg,
    &:focus svg {
      transform: scale(1.1);
    }
  }

  @media (min-width: 1124px) {
    gap: 0.5rem;
  }

  @media (min-width: 1400px) {
    font-size: 1.125rem;
    min-width: 18.625rem;
  }
}
