@import "../../styles/variables";

.testimonial {
  width: 100%;
  background-color: $text-color-secondary;
  color: $text-color;
  font-size: 0.75rem;
  border: 1px solid #ffffff;
  box-shadow: 0px 2px 4px 3px rgb(0 0 0 / 20%);
  border-radius: $border-radius;
  padding: 1rem 1.5rem;
  flex-direction: column;
  position: relative;
  flex-grow: 1;

  p + p {
    margin-top: 0.5rem;
  }

  &.video {
    overflow: hidden;
    padding: 0;
  }
  @media (min-width: 1024px) {
    padding: 1.5rem;
  }
}

.testimonialHeader {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  img {
    object-fit: contain;
    border-radius: 50%;
  }
}

.testimonialContent {
  font-size: 0.75rem;

  @media (min-width: 1400px) {
    font-size: 0.9rem;
  }
}

.titleContent {
  &.video {
    position: absolute;
    bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
  }
  z-index: 1;

  margin-left: 1rem;
  h3 {
    margin-bottom: 0.25rem;
    color: #0000ff;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    line-height: 1.2rem;

    &.video {
      color: $text-color-secondary;
    }
  }
  span {
    display: block;
    color: $text-color;

    &.video {
      color: $text-color-secondary;
    }
  }

  @media (min-width: 1400px) {
    font-size: 0.9rem;
  }

  &.hidden {
    display: none;
  }
}

.playIcon {
  background-image: url(/images/play-icon.svg);
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
}
