@import "../../../styles/variables";

.background {
  padding-top: 2.5rem;
  background: #051e32;

  &.paddingBottom {
    padding-bottom: 2.5rem;
  }
  @media (min-width: 1024px) {
    padding: 4.5rem 0;

    &.paddingBottom {
      padding: 4.5rem 0;
    }
  }
}
.mixedBackground {
  background-image: linear-gradient(to bottom, #051e32 100%, white 0%);
  padding-top: 2.5rem;
  padding-bottom: 3rem;

  @media (min-width: 475px) {
    background-image: linear-gradient(to bottom, #051e32 100%, white 0%);
    padding-bottom: 4rem;
  }
  @media (min-width: 680px) {
    background-image: linear-gradient(to bottom, #051e32 100%, white 0%);
    padding-bottom: 4rem;
  }
  @media (min-width: 768px) {
    background-image: linear-gradient(to bottom, #051e32 100%, white 0%);
    padding-bottom: 4rem;
  }
  @media (min-width: 915px) {
    background-image: linear-gradient(to bottom, #051e32 100%, white 0%);
    padding-bottom: 4rem;
  }
  @media (min-width: 1024px) {
    padding: 4.5rem 0;
    background-image: linear-gradient(to bottom, #051e32 100%, white 0%);
  }
  @media (min-width: 1300px) {
    background-image: linear-gradient(to bottom, #051e32 100%, white 0%);
  }
}

.content {
  width: 85%;
  max-width: $max-width;
  margin: 0 auto;
}

.testimonialsContent {
  width: 100%;
  margin: 0 auto;
}

.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 37rem;
  }
  @media (min-width: 1050px) {
    height: 36rem;
  }
  @media (min-width: 1100px) {
    height: 34.5rem;
  }
  @media (min-width: 1190px) {
    height: 31rem;
  }
  @media (min-width: 1300px) {
    height: 30rem;
  }
  @media (min-width: 1400px) {
    height: 34rem;
  }

  .columnDisplay {
    display: none;

    @media (min-width: 1024px) {
      display: flex;
    }
  }
}

.testimonialColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  gap: 1rem;
  @media (min-width: 1024px) {
    width: 31%;
    height: 100%;
  }
}
