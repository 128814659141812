@import "../../styles/variables";

.container {
  width: 100%;
  max-width: 21.875rem;
  padding: 1.5rem 1rem;
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.2);

  &:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  div {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  div h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 1.25rem;
    margin-left: 1rem;
  }
  div img {
    object-fit: contain;
  }

  span {
    display: block;
    font-style: oblique;
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }

  @media (min-width: 1024px) {
    margin: 1rem 0;
    padding: 1.7rem;
    border-radius: $border-radius;

    div {
      flex-direction: column;
      align-items: flex-start;
    }

    div img {
      width: 62px;
      height: 62px;
    }
    div h3 {
      margin-top: 1rem;
      margin-left: 0;
      font-size: 1.375rem;
    }
  }
}
