@import "../../styles/variables";

.container {
  font-size: 1.625rem;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 2.5rem;

  @media (min-width: 1024px) {
    font-size: 3rem;
    margin-bottom: 3rem;
  }
}
