@import "../../../styles/variables";

.container {
  width: 85%;
  max-width: $max-width;
  padding: 2.5rem 0;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 4.5rem 0;
  }

  ul {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 2rem;

    li {
      width: 100%;

      div {
        display: flex;
        align-items: center;
        padding-bottom: 1.25rem;
        border-bottom: 1px solid $brand-color;
      }

      div img {
        object-fit: contain;
        margin-right: 1rem;
      }

      div strong {
        font-size: 2.5rem;
        color: $brand-color;
      }

      p {
        margin-top: 1.25rem;
        font-size: 1.375rem;
        color: $text-color;
      }

      @media (min-width: 360px) {
        div strong {
          font-size: 3rem;
        }
      }
      @media (min-width: 1024px) {
        div {
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
        }

        div img {
          width: 54px;
          height: 54px;
          margin-bottom: 0.75rem;
        }

        div strong {
          font-size: 3.5rem;
        }
      }
    }
  }
}
