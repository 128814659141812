@import "../../styles/variables";

.container {
  font-size: 1.375rem;
  font-weight: bold;
  border: 3px solid $text-color-secondary;
  border-radius: $btn-border-radius;
  padding: 1.065rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  color: $text-color-secondary;

  &.blue {
    color: $brand-color;
    border-color: $brand-color;
  }

  &.white {
    color: $text-color-secondary;
    border-color: $text-color-secondary;
  }

  &.background {
    background: rgba(0, 0, 0, 0.5);
  }

  svg {
    min-width: 45px;
    margin-left: 0.5rem;
    @media (min-width: 660px) {
      margin-left: 1rem;
    }
  }
  svg path {
    transition: all 0.3s ease-in-out;
  }

  &:hover,
  &:focus {
    background-color: $brand-color;
    border-color: transparent;

    &.blue {
      color: $text-color-secondary;
      svg path,
      svg path {
        fill: white;
      }
    }
  }

  @media (min-width: 1024px) {
    justify-content: center;
  }
}
