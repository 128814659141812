@import "../../styles/variables";

.container {
  display: none;
  @media (min-width: 768px) {
    padding: 1rem;
  }

  @media (min-width: 1100px) {
    width: fit-content;
    background-color: transparent;
    margin-left: auto;
    position: fixed;
    z-index: 8;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
  }
}

.subScriptionButton {
  display: none;

  @media (min-width: 1100px) {
    width: 14.625rem;
    max-width: 430px;
    margin-left: auto;
    height: 100px;
    background-color: $brand-color;
    color: $text-color-secondary;
    font-weight: bold;
    font-size: 1.125rem;
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: background-color 0.5s;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;

    &:hover,
    &:focus {
      background-color: $highlight-color;
    }

    svg {
      width: 3.75rem;
      transition: transform 0.5s;
    }

    &:hover svg,
    &:focus svg {
      transform: scale(1.1);
    }
  }

  @media (min-width: 1100px) {
    gap: 0.5rem;
    width: 8.625rem;
    font-size: 0.7rem;
  }

  @media (min-width: 1144px) {
    width: 10.625rem;
  }

  @media (min-width: 1222px) {
    width: 14.625rem;
    font-size: 1rem;
  }

  @media (min-width: 1300px) {
    width: 17.625rem;
    font-size: 1rem;
  }

  @media (min-width: 1400px) {
    font-size: 1.125rem;
    width: 15.625rem;
  }

  @media (min-width: 1448px) {
    font-size: 1.125rem;
    min-width: 18.625rem;
  }
}
