@import "../../../styles/variables";

.container {
  background-image: linear-gradient(to bottom, #fff 80%, $soft-white 20%);
  padding: 2.5rem 0;
  @media (min-width: 1024px) {
    background-image: linear-gradient(to bottom, #fff 75%, $soft-white 25%);
    padding: 4.5rem 0;
  }
  @media (min-width: 1255px) {
    background-image: linear-gradient(to bottom, #fff 72%, $soft-white 28%);
  }
}

.content {
  color: $text-color;
  width: 85%;
  max-width: $max-width;
  margin: 0 auto;

  .paragraph {
    font-size: 1.25rem;
  }
}

.methods {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @media (min-width: 1230px) {
    column-gap: 1rem;
  }
  @media (min-width: 1255px) {
    justify-content: center;
    column-gap: 0.5rem;
  }
  @media (min-width: 1300px) {
    column-gap: 1rem;
  }
  @media (min-width: 1350px) {
    column-gap: 3rem;
  }
  @media (min-width: 1400px) {
    column-gap: 4.4rem;
  }
}
