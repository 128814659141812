@import "../variables";

.wrapper {
  padding-top: 6.25rem;
}

.background {
  background: #000 url("/images/hero-home-mbl.png") no-repeat top right;
  background-size: cover;

  @media (min-width: 420px) {
    background-image: url("/images/hero-home-dkt.png");
    background-position-x: 70%;
  }
}

.container {
  width: 85%;
  max-width: $max-width;
  padding: 2.5rem 0;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 4.5rem 0;
    height: 41.875rem;
    display: flex;
    align-items: center;
  }
}

.contentContainer {
  color: $text-color-secondary;

  p {
    font-size: 1.175rem;
    margin-bottom: 1.5rem;
    font-weight: 300;
    max-width: 16.875rem;

    @media (min-width: 370px) {
      font-size: 1.35rem;
    }

    @media (min-width: 1024px) {
      font-size: 1.475rem;
      margin-bottom: 2.5rem;
      max-width: 34.25rem;
    }
  }
}

.title {
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  width: 15rem;

  @media (min-width: 370px) {
    font-size: 1.65rem;
  }

  @media (min-width: 768px) {
    width: 26rem;
  }

  @media (min-width: 1024px) {
    font-size: 3.2rem;
    margin-bottom: 2rem;
    width: 42.25rem;
  }
}
