@import "../../../styles/variables";

.background {
  background: $soft-white;

  h2 {
    text-align: center;

    @media (min-width: 1024px) {
      text-align: left;
    }
  }
}

.partners {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;

  img {
    object-fit: contain;
  }

  @media (min-width: 700px) {
    grid-template-columns: repeat(3, auto);
    grid-gap: 4rem;
  }

  @media (min-width: 1024px) {
    margin-bottom: 4rem;
    grid-template-columns: repeat(4, auto);
    grid-gap: 4rem;
  }
}
